import * as React from "react"
import PropTypes from "prop-types"
import { useRef, useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import Player from "@vimeo/player"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${props => props.background};
`

const Iframe = styled.iframe`
  border: none;
  pointer-events: none;
  flex-shrink: 0;
  transition: opacity 2s ease-in-out;
`

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;

  z-index: -1;

  transition: opacity 0.5s ease-in-out;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${props => props.spinnerColor};
    border-radius: 50%;
    animation: ${spinAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.spinnerColor} transparent transparent
      transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const IframePanel = ({ url, backgroundColor, spinnerColor, ...props }) => {
  // reference on the wrapper to get the dimensions
  const wrapperRef = useRef(null)
  const playerRef = useRef(null)

  // states for the target dimensions
  const [targetWidth, setTargetWidth] = useState(0)
  const [targetHeight, setTargetHeight] = useState(0)

  // state for aspect ratio
  const [aspectRatio, setAspectRatio] = useState(0)

  // opacity state of iframe (to fade in on load)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    // function to calculate dimensions to create the "object-fit: cover" effect on the iframe element
    const calculateDimensions = () => {
      if (aspectRatio !== 0) {
        const width = wrapperRef.current.offsetWidth
        const height = wrapperRef.current.offsetHeight
        if (width / aspectRatio >= height) {
          setTargetWidth(width)
          setTargetHeight(width / aspectRatio)
        } else {
          setTargetHeight(height)
          setTargetWidth(height * aspectRatio)
        }
      }
    }
    // execute on first render
    calculateDimensions()

    // set resize listener so that it rerenders on every resize
    window.addEventListener("resize", calculateDimensions)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", calculateDimensions)
    }
  }, [aspectRatio])

  useEffect(() => {
    // set up player
    playerRef.current.src = playerRef.current.getAttribute("data-scr")
    const player = new Player(playerRef.current)

    // get aspect ration
    player.on("loaded", async () => {
      const width = await player.getVideoWidth()
      const height = await player.getVideoHeight()
      setAspectRatio(width / height)

      // remove this function
      player.off("loaded")
    })

    // set up fade in of video at the right time
    player.on("playing", () => {
      setPlaying(true)

      // remove this function
      player.off("playing")
    })
  }, [])

  return (
    <Wrapper ref={wrapperRef} background={backgroundColor} {...props}>
      <Spinner
        spinnerColor={spinnerColor}
        style={{ opacity: playing ? "0" : "1" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Spinner>
      <Iframe
        ref={playerRef}
        style={{
          width: `${targetWidth}px`,
          height: `${targetHeight}px`,
          opacity: playing ? "1" : "0",
        }}
        title="vimeo-player"
        src="about:blank"
        data-scr={`${url}?background=1&dnt=1`}
        allowFullScreen
      ></Iframe>
    </Wrapper>
  )
}

IframePanel.defaultProps = {
  backgroundColor: "transparent",
  spinnerColor: "#3d3d3d",
}

IframePanel.propTypes = {
  url: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
}

export default IframePanel
